<template>
  <div class="lottery-draw">
    <div class="lottery-draw-wrapper">
      <TemporaryDrawnNumber retail
                            :temp-drawn-number="tempDrawnNumber"/>
    </div>
    <DrawnNumbersBox retail />
  </div>
</template>

<script>
import DrawnNumbersBox from '@/components/DrawnNumbersBox';
import TemporaryDrawnNumber from '@/components/TemporaryDrawnNumber';
import { mapActions, mapGetters } from 'vuex';
import { slice } from 'lodash';
import EventBus from '@/store/EventBus';

export default {
  name: 'LotteryDraw',
  components: { TemporaryDrawnNumber, DrawnNumbersBox },
  data() {
    return {
      tempDrawnNumber: null,
      startNextStatus: false,
      newResultsDelay: 20000,
    };
  },
  props: {
    retail: Boolean,
  },
  computed: {
    ...mapGetters([
      'results',
      'drawnResults',
      'newDrawnCounter',
    ]),
  },
  methods: {
    ...mapActions([
      'setDrawnResults',
      'updateDrawnResults',
    ]),
    setTempDrawnNumber(number) {
      this.$set(this, 'tempDrawnNumber', number);
    },
    updateTempDrawnNumber() {
      let next = null;
      this.setTempDrawnNumber(null);
      const drawnNumbersLength = this.drawnResults.length;
      if (this.results) {
        next = drawnNumbersLength !== this.results.length
          ? this.results[drawnNumbersLength] : null;
      }
      if (next) {
        setTimeout(() => {
          this.setTempDrawnNumber(next);
          this.updateDrawnResults(next);
        }, 500);
        this.callUpdateTempDrawnNumber();
      } else {
        const deleay = this.results ? 10000 : 100;
        setTimeout(() => {
          this.startNextStatus = true;
          this.setDrawnResults([]);
          this.$store.commit('SET_RETAIL_DRAW_EVENT', this.$store.getters.prepareRetailDrawEvent);
          EventBus.$emit('LotteryDrawTitle', false);
          EventBus.$emit('curtainDown', 'drawCompleted');
          setTimeout(() => {
            EventBus.$emit('startAgainEverything');
          }, 3000);
        }, deleay);
      }
    },
    callUpdateTempDrawnNumber() {
      setTimeout(() => {
        this.updateTempDrawnNumber();
      }, 2000);
    },
  },
  mounted() {
    const drawnNumber = 0;
    const numbers = slice(this.results, 0, drawnNumber);
    this.setDrawnResults(numbers);
    this.setTempDrawnNumber(numbers[numbers.length - 1]);
    this.callUpdateTempDrawnNumber();
  },
};
</script>

<style lang="scss" scoped>
.lottery-draw {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  .lottery-draw-wrapper {
    height: calc(100% - 152px);
    @media (min-width: 1920px) {
      margin: 20px 0;
      height: calc(100% - 228px);
    }
  }
}
</style>
